<template>
  <b-container style="margin-top: 80px">
    <h4 class="display-3 mt-1 mb-4 font-weight-bold text-center">
      แบบฟอร์มสมัครสมาชิกชมรม D.A.R.E. ประเทศไทย
    </h4>

    <page-content class="mb-4">
      <b-row>
        <div class="col-12 py-3 text-right text-black-50">{{ dateNow }}</div>
        <div v-if="status" class="col-12 py-3 text-left">
          <h5>
            สถานะ : <span :style="colorStatus"> {{ status }} </span>
          </h5>
        </div>
        <div
          v-if="
            form &&
            status &&
            form.reject_cause &&
            status === 'ไม่ผ่านการอนุมัติ'
          "
          class="col-12 py-3"
        >
          <b-alert show variant="danger">{{ form.reject_cause }}</b-alert>
        </div>
        <div class="col-12 py-3">
          <h5>หมายเลขบัตรประชาชนที่ใช้ลงทะเบียน</h5>
          <div class="d-flex flex-row align-items-end flex-wrap">
            <div class="py-2 px-0 col-12 col-md-3">
              <input
                type="text"
                min="0"
                class="form-control"
                id="text-id-card"
                placeholder="x-xxxx-xxxxx-xx-x"
                v-model="form.id_card"
                v-mask="'#-####-#####-##-#'"
                @input="onInputIdCard"
                :disabled="!canEdit"
              />
            </div>
            <!-- <div class="py-2 col-12 col-md-9">
              <b-button variant="primary" size="sm" @click="checkAccessRegister"
                >ตรวจสอบ</b-button
              >
            </div> -->
          </div>
        </div>

        <div class="py-2 col-12 col-md-6">
          <label for="text-first_name">ชื่อ</label>
          <b-form-input
            type="text"
            id="text-first_name"
            v-model="form.first_name"
            :disabled="!canEdit"
          ></b-form-input>
        </div>
        <div class="py-2 col-12 col-md-6">
          <label for="text-last_name">นามสกุล</label>
          <b-form-input
            type="text"
            id="text-last_name"
            v-model="form.last_name"
            :disabled="!canEdit"
          ></b-form-input>
        </div>
        <div class="py-2 col-12 col-md-6">
          <label for="BDate">(วัน เดือน ปี เกิด)</label>
          <datepicker
            ref="datepicker"
            id="BDate"
            input-class="form-control"
            v-model="birthDate"
            required
            :language="th"
            :format="formatBuddhistBirthDate"
            :disabled="!canEdit"
          ></datepicker>
        </div>
        <div v-show="false" class="py-2 col-12 col-md-3">
          <label for="text-age">อายุ (ปี)</label>
          <b-form-input
            type="number"
            min="0"
            id="text-age"
            v-model="form.age"
            :disabled="!canEdit"
          ></b-form-input>
        </div>
        <div class="py-2 col-12 col-md-6">
          <label for="text-phone">เบอร์โทรศัพท์</label>
          <b-form-input
            id="text-phone"
            type="tel"
            name="tel"
            placeholder="0xx-xxx-xxxx"
            v-mask="'0##-###-####'"
            v-model="form.phone_number"
            :disabled="!canEdit"
          ></b-form-input>
        </div>
        <div class="py-2 col-12 col-md-6">
          <label for="text-email">E-mail</label>
          <b-form-input
            v-model="form.email"
            type="email"
            id="text-email"
            :disabled="!canEdit"
          ></b-form-input>
        </div>
        <div class="py-2 col-12 col-md-6">
          <label for="text-work-at">สถานที่ทำงาน</label>
          <b-form-input
            type="text"
            min="0"
            id="text-work-at"
            v-model="form.workplace_name"
            :disabled="!canEdit"
          ></b-form-input>
        </div>
        <div class="py-2 col-12 col-md-6">
          <label for="text-position_name">ตำแหน่ง</label>
          <b-form-input
            type="text"
            id="text-position_name"
            v-model="form.position_name"
            :disabled="!canEdit"
          ></b-form-input>
        </div>
        <div class="py-2 col-12 col-md-6">
          <label for="text-affiliation">สังกัด</label>
          <b-form-input
            type="text"
            id="text-affiliation"
            v-model="form.affiliation_name"
            :disabled="!canEdit"
          ></b-form-input>
        </div>
        <div class="py-2 col-12">
          <label for="text-address">ที่อยู่ปัจจุบัน</label>
          <b-form-textarea
            id="textarea"
            v-model="form.address"
            placeholder=""
            rows="3"
            :disabled="!canEdit"
          ></b-form-textarea>
        </div>
        <div class="py-2 col-12 col-md-4">
          <label for="text-work-at">จังหวัด</label>
          <v-select
            id="provinces"
            label="name_th"
            v-model="form.province"
            :options="provinces"
            @input="onInputProvince"
            :disabled="!canEdit"
          ></v-select>
        </div>
        <div class="py-2 col-12 col-md-4">
          <label for="text-amphures">อำเภอ</label>
          <v-select
            id="text-amphures"
            label="name_th"
            v-model="form.amphur"
            :options="amphures"
            @input="onInputAmphure"
            :disabled="!canEdit"
          ></v-select>
        </div>
        <div class="py-2 col-12 col-md-4">
          <label for="text-district">ตำบล</label>
          <v-select
            id="text-district"
            label="name_th"
            v-model="form.district"
            :options="tombons"
            :reduce="(tombons) => tombons.name_th"
            :disabled="!canEdit"
          ></v-select>
        </div>
        <div class="pt-5 col-12">
          <h5>หลักฐานประกอบการสมัคร</h5>
        </div>
        <div class="py-3 col-12 col-md-6">
          <label for="text-individual_img_url"
            >รูปถ่ายขนาด 1 นิ้ว จำนวน 1 รูป</label
          >
          <b-form-row>
            <image-card
              v-if="form.individual_img_url"
              :image="form.individual_img_url"
            ></image-card>

            <b-card v-else style="height: 190px" class="w-100 text-center"
              ><b-card-text class="mt-5"
                >ยังไม่ได้ทำการอัพโหลด</b-card-text
              ></b-card
            >
          </b-form-row>
          <!-- <b-form-file
            v-model="individual_img_url"
            placeholder="กรุณาเลือกรูปภาพ..."
            drop-placeholder="วางรูปภาพลงที่นี่..."
            accept=".jpg, .png, .jpeg"
            :disabled="!canEdit"
          ></b-form-file> -->
        </div>
        <div class="py-3 col-12 col-md-6">
          <label for="text-id_card_img_url">
            สำเนาบัตรประชาชน จำนวน 1 ฉบับ</label
          >
          <b-form-row>
            <image-card
              v-if="form.id_card_img_url"
              :image="form.id_card_img_url"
            ></image-card>

            <b-card v-else style="height: 190px" class="w-100 text-center"
              ><b-card-text class="mt-5"
                >ยังไม่ได้ทำการอัพโหลด</b-card-text
              ></b-card
            >
          </b-form-row>
          <!-- <b-form-file
            v-model="id_card_img_url"
            placeholder="กรุณาเลือกรูปภาพ..."
            drop-placeholder="วางรูปภาพลงที่นี่..."
            accept=".jpg, .png, .jpeg"
            :disabled="!canEdit"
          ></b-form-file> -->
        </div>

        <div v-if="isWaitingPayment || hasSlip" class="pt-5 col-12">
          <h5>หลักฐานการแจ้งชำระเงิน</h5>
        </div>
        <div v-if="isWaitingPayment || hasSlip" class="py-3 col-12 col-md-6">
          <label for="text-payment_img_url">แนบสลิป</label>
          <b-form-file
            v-if="!hasSlip"
            v-model="payment_img_url"
            placeholder="กรุณาเลือกรูปภาพ..."
            drop-placeholder="วางรูปภาพลงที่นี่..."
            accept=".jpg, .png, .jpeg"
            :state="checkingData ? (payment_img_url ? null : false) : null"
            @change="previewImages($event, 'payment')"
            :disabled="hasSlip"
          ></b-form-file>

          <small v-if="checkingData && !payment_img_url" class="text-danger">
            กรุณาอัพโหลดหลักฐานการแจ้งชำระเงิน
          </small>

          <b-form-row v-if="hasSlip">
            <image-card
              v-if="form.payment_img_url"
              :image="form.payment_img_url"
            ></image-card>

            <b-card v-else style="height: 190px" class="w-100 text-center"
              ><b-card-text class="mt-5"
                >ยังไม่ได้ทำการอัพโหลด</b-card-text
              ></b-card
            >
          </b-form-row>

          <carousel-teaching-image
            v-else
            :removable="!disabled"
            :is-loading="disabled"
            class="mt-2"
            :col="8"
            v-model="file_payment_preview"
            @change="removedImages_payment"
          >
            <div class="d-flex align-items-center">
              <b-spinner
                v-if="isProcessing_payment"
                label="processing image..."
                class="ml-4 mb-4"
              >
              </b-spinner>
            </div>
          </carousel-teaching-image>
        </div>

        <div
          v-if="isWaitingPayment"
          class="py-4 col-12"
          style="text-align: end"
        >
          <b-button variant="primary" @click="submitSilp" :disabled="hasSlip">
            <b-spinner
              v-if="isSubmiting"
              small
              label="submiting..."
            ></b-spinner>
            <span v-else>ยืนยันแจ้งชำระเงิน</span>
          </b-button>
        </div>
      </b-row>
    </page-content>
  </b-container>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
// import PageTitle from "../../components/layout/PageTitle";
import { formatBuddhistBirthDate } from "../../helpers/datepicker-helper";
import { th } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import vSelect from "vue-select";
import { Auth, Registration } from "../../models";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ImageCard from "../../components/carousel/ImageCard";
import CarouselTeachingImage from "../../components/carousel/TeachingImage";
import Compressor from "compressorjs";

export default {
  mixins: [validationMixin],

  components: {
    PageContent,
    // PageTitle
    Datepicker,
    vSelect,
    ImageCard,
    CarouselTeachingImage,
  },

  data() {
    return {
      th: th,
      provinces: [],
      amphures: [],
      tombons: [],
      isSubmiting: false,
      checkingData: false,
      showModalOTP: false,
      canEdit: false,
      checkingSubmit: false,
      checkingOTP: false,
      otpTimeout: 0,
      optInterval: null,
      otp_code: null,
      token: "",
      individual_img_url: null,
      id_card_img_url: null,
      payment_img_url: null,
      birthDate: null,
      status: null,
      hasSlip: false,
      isWaitingPayment: false,
      isProcessing_payment: false,
      file_payment_preview: [],
      disabled: false,
      form: {
        phone_number: "",
        first_name: "",
        last_name: "",
        bd_date: "",
        age: null,
        workplace_name: "",
        position_name: "",
        affiliation_name: "",
        address: "",
        province: "",
        amphur: "",
        district: "",
        email: "",
        individual_img_url: null,
        id_card_img_url: null,
        id_card: "",
        reject_cause: "",
      },
    };
  },

  validations: {
    // form: {
    //   phone_number: { required },
    //   first_name: { required },
    //   last_name: { required },
    //   bd_date: { required },
    // },
    // individual_img_url: { required },
    // id_card_img_url: { required },
    payment_img_url: { required },
  },

  watch: {
    otpTimeout: "onOtpTimeoutChanged",

    birthDate: {
      handler: "onBirthDateChanged",
    },

    registrationCode: {
      immediate: true,
      handler: "fetch",
    },
  },

  computed: {
    dateNow() {
      const tmpDate = new Date();

      let monthNames = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ];

      let day = tmpDate.getDate();

      let monthIndex = tmpDate.getMonth();
      let monthName = monthNames[monthIndex];

      let year = tmpDate.getFullYear() + 543;

      return `วันที่ ${day} เดือน ${monthName} พ.ศ. ${year}`;
    },

    registrationCode() {
      return this.$route.query && this.$route.query.code
        ? this.$route.query.code
        : null;
    },

    colorStatus() {
      const { status } = this;
      if (!status) {
        return "";
      }
      if (status === "ลงทะเบียนสำเร็จ") {
        return "color: #5aaf2b";
      }
      if (status === "ไม่ผ่านการอนุมัติ") {
        return "color: #ea484d";
      }
      return "";
    },
  },

  methods: {
    formatBuddhistBirthDate,

    async fetch(code) {
      if (!code) return;
      let { response } = await Registration.api().findByCode(code);

      if (response && response.data) {
        this.form = {
          ...response.data.data,
        };
        this.birthDate = response.data.data.bd_date
          ? new Date(response.data.data.bd_date).setFullYear(
              new Date(response.data.data.bd_date).getFullYear() - 543
            )
          : null;

        this.status = response.data.data.status;

        if (response.data.data.status == "รอชำระเงิน") {
          this.isWaitingPayment = true;
        }

        if (response.data.data.payment_img_url) {
          this.hasSlip = true;
        }
      }
    },

    async previewImages(event, key = "") {
      let { maxFiles } = this;

      if (maxFiles <= 0) {
        return;
      }

      const self = this;

      var input = event.target;

      const maxAllowedSize = 15 * 1024 * 1024;

      Array.from(input.files).forEach((file) => {
        if (file.size <= maxAllowedSize) {
          this[`file_${key}_preview`].splice(0, 1);

          this[`isProcessing_${key}`] = true;

          new Compressor(file, {
            quality: 0.8,

            maxWidth: this.imageWidth,

            maxHeight: this.imageHeight,

            success(result) {
              var reader = new FileReader();

              reader.onload = async (e) => {
                self[`file_${key}_preview`].push({
                  file: result,
                  raw: e.target.result,
                });

                self[`isProcessing_${key}`] = false;
              };

              reader.readAsDataURL(result);
            },
            error(err) {
              console.log(err.message);
              this.$toast.error("ไม่สามารถอัพโหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง");
            },
          });
        } else {
          this.$toast.error(
            "ไม่สามารถอัพโหลดไฟล์ขนาดใหญ่เกิน 15 MB ได้ กรุณาลองใหม่อีกครั้ง"
          );
        }
      });

      // input.value = "";
    },

    onBirthDateChanged(date) {
      const dObj = new Date(new Date(date).getTime());

      dObj.setFullYear(dObj.getFullYear() + 543);
      dObj.setHours(0);
      dObj.setMinutes(0);
      dObj.setSeconds(0);

      this.$set(this.form, "bd_date", dObj.toISOString());
    },

    async submitSilp() {
      this.checkingData = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return this.$toast.error(`กรุณาแนบหลักฐานการชำระเงิน`);
      }

      this.isSubmiting = true;

      try {
        const presignPromise = await Registration.api().getUploadUrl({
          phone_number: this.form.phone_number.replace(/-/g, ""),
          file_name: this.payment_img_url.name,
          contentType: this.payment_img_url.type,
        });

        if (presignPromise && presignPromise.response) {
          const { url, fullUrl } = presignPromise.response.data;

          if (url) {
            await axios.put(url, this.payment_img_url);
          }
          this.form.payment_img_url = fullUrl;
        }

        await Registration.api().update(this.form.id, {
          ...this.form,
          phone_number: this.form.phone_number.replace(/-/g, ""),
        });

        const { response } =
          await Registration.api().updateStatusWaitingForApprove(this.form.id, {
            payment_img_url: this.form.payment_img_url,
          });

        if (response && response.data && response.data.data) {
          this.status = response.data.data.status;
          this.hasSlip = true;
          this.$toast.success(response.data.message);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isSubmiting = false;
      }
    },

    async submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      }

      this.checkingSubmit = true;

      let { phone_number } = this.form;

      phone_number = phone_number.replace(/-/g, "");

      try {
        const token = await Auth.api().getOTP(phone_number);

        this.token = token;

        this.showModalOTP = true;
      } catch (message) {
        this.$toast.error(message);
      } finally {
        this.checkingSubmit = false;
      }
    },

    async sendForm() {
      this.isSubmiting = true;
      this.canEdit = false;
      try {
        const presignPromise_1 = await Registration.api().getUploadUrl({
          phone_number: this.form.phone_number.replace(/-/g, ""),
          file_name: this.individual_img_url.name,
          contentType: this.individual_img_url.type,
        });

        if (presignPromise_1 && presignPromise_1.response) {
          const { url, fullUrl } = presignPromise_1.response.data;

          if (url) {
            await axios.put(url, this.individual_img_url);
          }
          this.form.individual_img_url = fullUrl;
        }

        const presignPromise_2 = await Registration.api().getUploadUrl({
          phone_number: this.form.phone_number.replace(/-/g, ""),
          file_name: this.id_card_img_url.name,
          contentType: this.id_card_img_url.type,
        });

        if (presignPromise_2 && presignPromise_2.response) {
          const { url, fullUrl } = presignPromise_2.response.data;

          if (url) {
            await axios.put(url, this.id_card_img_url);
          }
          this.form.id_card_img_url = fullUrl;
        }

        await Registration.api().create({
          ...this.form,
          phone_number: this.form.phone_number.replace(/-/g, ""),
        });
        // console.log(response);
      } catch (error) {
        console.error(error);
      } finally {
        this.isSubmiting = false;
      }
    },

    setOtpTimeout() {
      this.otpTimeout = 30;
    },

    onOtpTimeoutChanged(v) {
      if (v > 0 && !this.optInterval) {
        this.optInterval = setInterval(() => {
          this.otpTimeout -= 1;
        }, 1000);
      } else {
        if (v === 0) {
          clearInterval(this.optInterval);
          this.optInterval = null;
        }
      }
    },

    async validateOTP() {
      this.checkingOTP = true;
      try {
        if (!this.otp_code.length) {
          throw "กรุณากรอกรหัส OTP";
        }

        const valid = await Auth.api().validateOTP(this.token, this.otp_code);

        if (valid) {
          this.sendForm();
          this.showModalOTP = false;
          this.$toast.success("ยืนยันเบอร์มือถือสำเร็จ");

          // setTimeout(() => {
          //   location.href =
          //     decodeURIComponent(this.$route.query.redirectUrl) || "/";
          // }, 1000);
        }
      } catch (message) {
        console.log(message);
        this.$toast.error(message);
      } finally {
        this.checkingOTP = false;
      }
    },

    onInputIdCard() {
      const count_id_card = this.form.id_card.replace(/-/g, "");
      if (!this.form.id_card || count_id_card.length != 13) {
        this.canEdit = false;
      }
    },

    async checkAccessRegister() {
      let check_id_card = this.chkDigitPid(this.form.id_card);

      const count_id_card = this.form.id_card.replace(/-/g, "");

      if (!this.form.id_card || count_id_card.length != 13 || !check_id_card) {
        this.canEdit = false;
        return this.$toast.error("ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง");
      }

      try {
        const { response } = await Registration.api().checkIdCard({
          id_card: this.form.id_card.replace(/-/g, ""),
        });

        if (response && response.data && response.data.valid) {
          this.canEdit = true;
          if (response.data.data) {
            const { data } = response.data;
            this.form.m_rank_id = data.m_rank ? data.m_rank.id : null;
            this.rank = data.m_rank ? data.m_rank.m_rank_name : "";
            this.form.first_name = data.first_name ? data.first_name : "";
            this.form.last_name = data.last_name ? data.last_name : "";
            this.birthDate = data.bd_date
              ? new Date(data.bd_date).setFullYear(
                  new Date(data.bd_date).getFullYear() - 543
                )
              : null;
            this.form.phone_number = data.phone_number ? data.phone_number : "";
            this.form.email = data.email ? data.email : "";
            this.form.affiliation_name = data.m_division
              ? data.m_division.m_division_name
              : "";
            this.form.workplace_name = data.m_station
              ? data.m_station.m_station_name
              : "";
            this.form.position_name = data.position_name
              ? data.position_name
              : "";
            this.form.address = data.address ? data.address : "";
            this.form.user_profile_id = data ? data.id : null;
          } else {
            this.form.m_rank_id = null;
            this.rank = "";
            this.form.first_name = "";
            this.form.last_name = "";
            this.birthDate = null;
            this.form.phone_number = "";
            this.form.email = "";
            this.form.affiliation_name = "";
            this.form.workplace_name = "";
            this.form.position_name = "";
            this.form.address = "";
            this.form.user_profile_id = null;
          }
          this.$toast.success(
            "หมายเลขบัตรประชาชนนี้ สามารถใช้สมัครสมาชิกครูแดร์ได้"
          );
        } else {
          this.canEdit = false;
          return this.$toast.error(
            "หมายเลขบัตรประชาชนนี้ อยู่ในระบบเรียบร้อยแล้ว"
          );
        }
      } catch (error) {
        this.canEdit = false;
        return this.$toast.error("เบอร์โทรศัพท์นี้ อยู่ในระบบเรียบร้อยแล้ว");
      }
    },

    async getProvinces() {
      let { data } = await axios.get(
        "https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province_with_amphure_tombon.json"
      );

      if (data) {
        this.provinces = data;
      }
    },

    onInputProvince(value) {
      this.form.province = value && value.name_th ? value.name_th : "";
      this.form.amphur = "";
      this.form.district = "";
      this.amphures = value && value.amphure ? value.amphure : [];
    },

    onInputAmphure(value) {
      this.form.amphur = value && value.name_th ? value.name_th : "";
      this.form.district = "";
      this.tombons = value && value.tombon ? value.tombon : [];
    },

    removedImages_payment() {
      this.payment_img_url = null;
    },
  },

  mounted() {
    this.getProvinces();
  },
};
</script>
