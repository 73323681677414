<template>
  <b-col :cols="col">
    <b-card>
      <!-- <a
          v-show="removable"
          href="javascript:void(0)"
          class="btn-remove-teaching-img"
          @click="remove(index)"
        >
          <font-awesome-icon class="mx-auto" icon="window-close" />
        </a> -->
      <div
        v-if="image"
        class="img-fluid w-100 cursor-pointer"
        :style="getStyle(image)"
        @click="openFullScreen(image)"
      ></div>
    </b-card>

    <b-modal
      id="fullscreen-modal"
      v-model="showFullScreen"
      centered
      scrollable
      size="xl"
      header-class="modal__header"
      footer-class="modal__footer"
      body-class="modal__body"
      hide-footer
      @hidden="onHidden"
    >
      <b-card>
        <img
          v-if="fullScreenImage"
          class="d-block img-fluid w-100"
          :src="fullScreenImage"
        />
      </b-card>
    </b-modal>
  </b-col>
</template>

<script>
// import Slick from "vue-slick";

export default {
  props: {
    image: String,
    col: {
      type: Number,
      default: 12,
    },
  },

  model: {
    prop: "images",
    event: "change",
  },

  components: {
    // Slick,
  },

  data() {
    return {
      showFullScreen: false,
      fullScreenImage: null,
      fullScreenImageWidth: null,
      fullScreenImageHeight: null,
      //   slickOptions: {
      //     infinite: true,
      //     slidesToShow: 2,
      //     slidesToScroll: 1,
      //     dots: true,
      //     arrows: true,
      //     responsive: [
      //       {
      //         breakpoint: 1024,
      //         settings: {
      //           slidesToShow: 2,
      //         },
      //       },
      //     ],
      //   },
    };
  },

  watch: {
    // images: "reInit",
  },

  methods: {
    // reInit() {
    //   this.$nextTick(() => {
    //     this.$refs.slick.reSlick();
    //   });
    // },
    openFullScreen(image) {
      // const self = this;
      // const tempImage = new Image();

      // tempImage.onload = function () {
      //   self.fullScreenImageWidth = this.width;
      //   self.fullScreenImageHeight = this.height;
      //   self.showFullScreen = true;
      // };

      // tempImage.src = image.teaching_actual_img
      //   ? image.teaching_actual_img
      //   : image.raw;

      this.fullScreenImage = image;
      this.showFullScreen = true;
    },

    onHidden() {
      this.fullScreenImage = null;
      // this.fullScreenImageWidth = null;
      // this.fullScreenImageHeight = null;
    },

    // remove(index) {
    //   const removedImages = [...this.images];

    //   removedImages.splice(index, 1);

    //   this.$emit("change", removedImages);
    // },

    getStyle(image) {
      return {
        height: "150px",
        backgroundImage: `url('${image}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
      };
    },
  },
};
</script>
